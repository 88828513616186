// Note: the following regexp was taken from:
// https://github.com/chalk/ansi-regex/blob/main/index.js
//
// It was not imported due to ESM module incompatibility issues :/

const ansiPattern = [
  "[\\u001B\\u009B][[\\]()#;?]*(?:(?:(?:(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]+)*|[a-zA-Z\\d]+(?:;[-a-zA-Z\\d\\/#&.:=?%@~_]*)*)?\\u0007)",
  "(?:(?:\\d{1,4}(?:;\\d{0,4})*)?[\\dA-PR-TZcf-nq-uy=><~]))",
].join("|");

const ansiRegex = new RegExp(ansiPattern, "g");

export function stripAnsi(str: string) {
  return str.replace(ansiRegex, "");
}
