export function log10(n: bigint) {
  if (n <= 0) {
    return 0;
  }
  const s = n.toString(10);
  return s.length + Math.log10(Number("0." + s.substring(0, 15)));
}

export function abs(n: bigint): bigint {
  return n < 0 ? -n : n;
}

export function coerce(n: string | number | bigint): bigint {
  return typeof n === "bigint"
    ? n
    : BigInt(typeof n === "number" ? Math.round(n) : n);
}
