import pluralizeWord from "pluralize";

import { formatNumber } from "./format";

// add specific rules - https://www.npmjs.com/package/pluralize#usage
//
// pluralize.plural('regex') //=> "regexes"
// pluralize.addPluralRule(/gex$/i, 'gexii')
// pluralize.plural('regex') //=> "regexii"

export function pluralize(
  count: number | null | undefined,
  word: string,
  format = formatNumber
) {
  count ??= 0;
  return `${format(count)} ${pluralizeWord(word, count)}`;
}
