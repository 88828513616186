import React from "react";
import { PayloadError } from "relay-runtime";

import { Card } from "@dewrangle/ui";

export type MutationError = {
  __typename?: string;
  field?: readonly string[];
  message?: string;
};

export type PayloadOrMutationErrors =
  | readonly (PayloadError | MutationError)[]
  | null;

function hasErrorsSource<T>(
  error: any
): error is T & { source: { errors: PayloadError[] } } {
  return Array.isArray(error?.source?.errors);
}

function coerceGraphQLErrors(errors: PayloadOrMutationErrors) {
  return errors?.flatMap((error) =>
    hasErrorsSource(error) ? error.source.errors : [error]
  );
}

export const FormErrors = ({ errors }: { errors: PayloadOrMutationErrors }) =>
  errors?.length ? (
    <Card className="p-4">
      <span className="text-xs text-destructive">
        {coerceGraphQLErrors(errors)?.map(({ message }) => (
          <React.Fragment key={message}>
            {message}
            <br />
          </React.Fragment>
        ))}
      </span>
    </Card>
  ) : null;
