import { ChevronRight, Package } from "lucide-react";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Children, ComponentPropsWithoutRef, ReactNode, Suspense } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { clsxm } from "@peykio/clsxm";

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@dewrangle/ui";

import { Layout, QuickLink } from "@/components/uikit";
import type { Navigation_NavigationHeaderQuery } from "@/queries/__generated__/Navigation_NavigationHeaderQuery.graphql";
import type { Navigation_NavigationOrganizationDropdownQuery } from "@/queries/__generated__/Navigation_NavigationOrganizationDropdownQuery.graphql";
import type { Navigation_NavigationProfileDropdown$key } from "@/queries/__generated__/Navigation_NavigationProfileDropdown.graphql";
import type { Navigation_NavigationStudyLinkQuery } from "@/queries/__generated__/Navigation_NavigationStudyLinkQuery.graphql";

export const Row = ({
  className = "",
  ...rest
}: JSX.IntrinsicElements["div"]) => (
  <div
    className={clsxm("flex items-center justify-start", {
      [className]: className,
    })}
    {...rest}
  />
);

export const NavigationOrganizationDropdown = ({
  className,
}: {
  className?: string;
}) => {
  const router = useRouter();
  const query =
    useLazyLoadQuery<Navigation_NavigationOrganizationDropdownQuery>(
      graphql`
        query Navigation_NavigationOrganizationDropdownQuery(
          $organization: ID!
        ) {
          organization: node(id: $organization) {
            id
            ... on Organization {
              name
            }
          }
        }
      `,
      { organization: (router.query.organization ?? "") as string }
    );

  return (
    <Row className={clsxm(["gap-x-2", className])}>
      <QuickLink
        href="/[organization]"
        pick={["organization", "search", "filter", "sortBy", "sortOrder"]}
        className="truncate underline-offset-4 focus:outline-none hover:underline focus:underline"
      >
        {query?.organization?.name}
      </QuickLink>
    </Row>
  );
};

export const NavigationStudyLink = () => {
  const router = useRouter();
  const query = useLazyLoadQuery<Navigation_NavigationStudyLinkQuery>(
    graphql`
      query Navigation_NavigationStudyLinkQuery($study: ID!) {
        study: node(id: $study) {
          id
          ... on Study {
            name
          }
        }
      }
    `,
    { study: router.query.study as string },
    { fetchPolicy: "store-and-network" }
  );

  return (
    <QuickLink
      href="/[organization]/[study]"
      className="truncate underline-offset-4 focus:outline-none hover:underline focus:underline"
    >
      {query?.study?.name}
    </QuickLink>
  );
};

export const NavigationProfileDropdown = ({
  queryRef,
}: {
  queryRef: Navigation_NavigationProfileDropdown$key;
}) => {
  const query = useFragment(
    graphql`
      fragment Navigation_NavigationProfileDropdown on Query {
        viewer {
          image
          email
          name
        }
      }
    `,
    queryRef
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="outline rounded-full overflow-hidden"
        aria-label="Profile Dropdown"
      >
        <Avatar className="h-8 w-8">
          <AvatarImage src={query.viewer?.image || ""} />
          <AvatarFallback>{query.viewer?.name}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <Link href="/" passHref>
          <DropdownMenuItem>Dashboard</DropdownMenuItem>
        </Link>
        <Link href="/profile/settings" passHref>
          <DropdownMenuItem>Settings</DropdownMenuItem>
        </Link>
        <Link href="/" passHref>
          <DropdownMenuItem onClick={() => signOut()}>Logout</DropdownMenuItem>
        </Link>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const NavigationBreadcrumbs = ({
  children,
  className = "",
}: {
  children: ReactNode;
  className?: string;
}) => (
  <Row className={clsxm("gap-x-4", { [className]: className?.length })}>
    {Children.map(children, (child, i) =>
      child ? (
        <>
          {i !== 0 && (
            <div>
              <ChevronRight className="h-4 w-4 text-muted-foreground" />
            </div>
          )}
          {child}
        </>
      ) : null
    )}
  </Row>
);

export type NavigationBarProps = {
  left?: React.ReactNode;
  right?: React.ReactNode;
  newline?: React.ReactNode;
} & JSX.IntrinsicElements["header"];

export const NavigationBar = ({
  children,
  className = "",
  left,
  right,
  newline,
  ...rest
}: NavigationBarProps): JSX.Element => (
  <header
    className={clsxm("flex items-center", { [className]: className?.length })}
    {...rest}
  >
    <Layout.Grid className="h-full border-b border-accent py-4">
      {left && (
        <div className="col-span-3 flex items-center">
          <Layout.Row>{left}</Layout.Row>
        </div>
      )}
      <div
        className={clsxm(
          "hidden md:flex items-center space-x-6 col-span-12 overflow-x-auto",
          {
            "col-span-9": right || left,
            "col-span-6": right && left,
          }
        )}
      >
        {children}
      </div>
      {right && (
        <div className="flex items-center col-start-10 col-span-3 justify-end gap-x-4">
          {right}
        </div>
      )}
      {newline && <div className="col-span-full">{newline}</div>}
    </Layout.Grid>
  </header>
);

export const NavigationHeader = ({
  children,
}: ComponentPropsWithoutRef<"header">) => {
  const router = useRouter();
  const query = useLazyLoadQuery<Navigation_NavigationHeaderQuery>(
    graphql`
      query Navigation_NavigationHeaderQuery {
        ...Navigation_NavigationProfileDropdown
      }
    `,
    {}
  );

  return (
    <NavigationBar
      right={<NavigationProfileDropdown queryRef={query} />}
      newline={children && <Layout.Row>{children}</Layout.Row>}
      className="col-span-9"
    >
      <NavigationBreadcrumbs className="w-full">
        <Link href="/" passHref>
          <Package className="h-6 w-6 text-primary" aria-label="Home" />
        </Link>
        {router?.query?.organization && (
          <Suspense fallback={"..."}>
            <NavigationOrganizationDropdown
              className={clsxm({ "max-w-[40%]": !!router?.query?.study })}
            />
          </Suspense>
        )}
        {router?.query?.study && (
          <Suspense fallback={"..."}>
            <NavigationStudyLink />
          </Suspense>
        )}
      </NavigationBreadcrumbs>
    </NavigationBar>
  );
};
