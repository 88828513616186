import { ComponentPropsWithRef } from "react";

import { clsxm } from "@peykio/clsxm";

import { Layout } from "@/components/uikit";

export const variants = {
  base: "bg-background",
  ghost: "bg-transparent",
};

export type ListProps = {
  variant?: keyof typeof variants;
} & ComponentPropsWithRef<"div">;

export const List = ({
  className = "",
  variant = "base",
  ...rest
}: ListProps): JSX.Element => (
  <Layout.Column
    className={clsxm(
      "gap-0 w-full divide-y border divide-accent border-accent rounded-lg",
      {
        [variants[variant]]: variant,
        [className]: className?.length,
      }
    )}
    {...rest}
  />
);

export const ListItem = ({
  children,
  className = "",
  ...rest
}: ComponentPropsWithRef<"div">): JSX.Element => (
  <Layout.Row
    className={clsxm("p-4 not-prose", {
      [className]: className?.length,
    })}
    {...rest}
  >
    {children}
  </Layout.Row>
);
