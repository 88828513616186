/**
 * @generated SignedSource<<56c10f5c4c7b885602f9d7f4923544fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Navigation_NavigationHeaderQuery$variables = Record<PropertyKey, never>;
export type Navigation_NavigationHeaderQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Navigation_NavigationProfileDropdown">;
};
export type Navigation_NavigationHeaderQuery = {
  response: Navigation_NavigationHeaderQuery$data;
  variables: Navigation_NavigationHeaderQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Navigation_NavigationHeaderQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Navigation_NavigationProfileDropdown"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Navigation_NavigationHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "image",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8692f754133a1abe4a728e5ed595c31e",
    "id": null,
    "metadata": {},
    "name": "Navigation_NavigationHeaderQuery",
    "operationKind": "query",
    "text": "query Navigation_NavigationHeaderQuery {\n  ...Navigation_NavigationProfileDropdown\n}\n\nfragment Navigation_NavigationProfileDropdown on Query {\n  viewer {\n    image\n    email\n    name\n    id\n  }\n}\n"
  }
};

(node as any).hash = "5a81443308f1a292437e94800237ce64";

export default node;
