export const base = (toEncode: string): string => {
  return Buffer.from(toEncode).toString("base64");
};

export const debase = (toDecode: string): string => {
  return Buffer.from(toDecode, "base64").toString("utf-8");
};

/*
Base64 encodes three binary octets into four characters. 
You have 4N Base64 characters. Let k be the number of trailing '=' chars.
Padding chars: 0, 1 or 2 (= for 1 and == for 2)
Meta `data:image/png;base64,` needs to be removed
*/
export const originalByteLength = (base64String: string) => {
  const padding = base64String.endsWith("==")
    ? 2
    : base64String.endsWith("=")
      ? 1
      : 0;
  const base64StringWithoutMeta = base64String.split(",")[1] ?? base64String;
  return 3 * Math.ceil(base64StringWithoutMeta.length / 4) - padding;
};
