import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const useTimer = (
  seconds: number,
  initial = true
): [boolean, Dispatch<SetStateAction<boolean>>, number] => {
  const [value, setValue] = useState(initial);
  const [countdown, setCountdown] = useState(seconds);

  useEffect(() => {
    if (value !== initial) {
      const interval = setInterval(() => setCountdown((s) => s - 1), 1000);
      const timer = setTimeout(() => {
        clearInterval(interval);
        setValue(initial);
        setCountdown(seconds);
      }, seconds * 1000);
      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
    return undefined;
  }, [value, initial, seconds]);

  return [value, setValue, countdown];
};
