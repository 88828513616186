import { ComponentPropsWithRef, forwardRef } from "react";

import { clsxm } from "@peykio/clsxm";

export const Main = ({
  className = "",
  ...rest
}: JSX.IntrinsicElements["main"]): JSX.Element => (
  <main
    className={clsxm("relative min-h-screen", {
      [className]: className,
    })}
    {...rest}
  />
);

export const Row = forwardRef<HTMLDivElement, ComponentPropsWithRef<"div">>(
  ({ className = "", ...rest }, forwardRef): JSX.Element => (
    <div
      className={clsxm("flex items-center gap-x-4", {
        [className]: className,
      })}
      {...rest}
      ref={forwardRef}
    />
  )
);

export const Column = forwardRef<HTMLDivElement, ComponentPropsWithRef<"div">>(
  ({ className = "", ...rest }, forwardRef): JSX.Element => (
    <div
      className={clsxm("flex flex-col gap-y-6", {
        [className]: className,
      })}
      {...rest}
      ref={forwardRef}
    />
  )
);

export const Grid = ({
  className = "",
  ...rest
}: JSX.IntrinsicElements["div"]): JSX.Element => (
  <div
    className={clsxm(
      "relative mx-auto w-full max-w-6xl grid gap-6 grid-cols-12",
      {
        [className]: className.length,
      }
    )}
    {...rest}
  />
);

export const Article = ({
  className = "",
  ...rest
}: JSX.IntrinsicElements["article"]): JSX.Element => (
  <article
    className={clsxm("col-span-12 max-w-3xl lg:col-span-9", {
      [className]: className,
    })}
    {...rest}
  />
);

export const Aside = ({
  className = "",
  ...rest
}: JSX.IntrinsicElements["aside"]): JSX.Element => (
  <aside
    className={clsxm("hidden lg:block col-span-3", { [className]: className })}
    {...rest}
  />
);
