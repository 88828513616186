import { format, formatDistance } from "date-fns";
import hn from "human-number";

import { abs, coerce, log10, percent } from "@/lib/math";

export function formatDate(
  d?: Date | null,
  {
    fallback = "-",
    withTime = true,
  }: { fallback?: string; withTime?: boolean } = {}
) {
  return d ? format(d, withTime ? "p d-MMM-yyyy" : "d-MMM-yyyy") : fallback;
}

export function formatDurationMs(
  ms?: bigint | string | number | null,
  { fallback = "-" }: { fallback?: string } = {}
) {
  return ms !== null && ms !== undefined
    ? formatDistance(0, Number(ms ?? 0), { includeSeconds: true })
    : fallback;
}

export function formatDuration(
  d1?: number | Date | null,
  d2?: number | Date | null,
  { fallback = "0" }: { fallback?: string } = {}
) {
  return d1 && d2 ? formatDistance(d1, d2, { includeSeconds: true }) : fallback;
}

/**
 * Shamelessly adapted from: https://github.com/sindresorhus/pretty-bytes
 * In order to:
 *  - resolve package.json issues
 *  - support bigint
 */
export function formatFileSize(
  bytes?: number | bigint | string | null,
  options?: {
    locale?: string;
    localeOptions?: Intl.NumberFormatOptions & BigIntToLocaleStringOptions;
  }
) {
  const UNITS = ["B", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const normalized = abs(coerce(bytes ?? 0n));

  const exponent = Math.min(
    Math.floor(log10(normalized) / 3),
    UNITS.length - 1
  );

  return [
    Math.round(Number(normalized) / 1000 ** exponent).toLocaleString(
      options?.locale,
      options?.localeOptions
    ),
    UNITS[exponent],
  ].join(" ");
}

export function formatNumber(
  n?: number | string | bigint | null,
  { fallback = "0" }: { fallback?: string } = {},
  map: (n: number) => string = (n) => n.toFixed(0)
) {
  return n ? hn(Number(n ?? 0), map) : fallback;
}

export function formatPercent(
  n?: string | number | null,
  d?: string | number | null,
  { fallback = "-" }: { fallback?: string } = {}
) {
  return d ? `${percent(Number(n ?? 0), Number(d ?? 0))}%` : fallback;
}
