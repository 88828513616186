/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentPropsWithoutRef,
  JSXElementConstructor,
  ReactElement,
  cloneElement,
} from "react";

import { clsxm } from "@peykio/clsxm";

export const variants = {
  base: "",
  primary: "text-primary",
  accent: "text-accent",
  danger: "text-destructive",
  warning: "text-warning",
  success: "text-success",
};

export const numSizes = {
  sm: "text-2xl font-semibold",
  md: "text-4xl font-semibold",
  lg: "text-5xl font-bold",
  xl: "text-6xl font-bold",
};
export const titleSizes = {
  sm: "",
  md: "text-lg",
  lg: "text-xl",
  xl: "text-2xl",
};
export const iconSizes = {
  sm: "mt-1.5 h-5 w-5",
  md: "h-6 w-6",
  lg: "h-8 w-8",
  xl: "mt-3 h-10 w-10",
};
export const displayOrientation = {
  vertical: "flex flex-col",
  horizontal: "flex  flex-row",
};

export type StatsProps = {
  value: React.ReactNode;
  icon?: ReactElement<any, string | JSXElementConstructor<any>>;
  variant?: keyof typeof variants;
  size?: keyof typeof numSizes;
  orientation?: keyof typeof displayOrientation;
} & ComponentPropsWithoutRef<"div">;

export const Stats = ({
  variant = "base",
  size = "md",
  className = "",
  value,
  children,
  icon,
  orientation = "horizontal",
  ...rest
}: StatsProps): JSX.Element => (
  <div
    className={clsxm("gap-x-2 not-prose", {
      [displayOrientation[orientation]]: orientation,
      [className]: className.length,
    })}
    {...rest}
  >
    {icon &&
      cloneElement(icon, {
        "aria-hidden": true,
        className: clsxm("stroke-[1.5px] mt-2 opacity-80 flex-shrink-0", {
          [iconSizes[size]]: size,
          [variants[variant]]: variant,
          [icon?.props?.className]: icon?.props?.className,
        }),
      })}
    <div>
      <div
        className={clsxm("text-foreground", {
          [numSizes[size]]: size,
          [variants[variant]]: variant,
        })}
      >
        {value}
      </div>
      <p
        className={clsxm("", {
          [titleSizes[size]]: size,
        })}
      >
        {children}
      </p>
    </div>
  </div>
);
