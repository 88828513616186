import { AccessibleIcon } from "@radix-ui/react-accessible-icon";
import { ClipboardCheck, ClipboardCopy } from "lucide-react";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import UseAnimations from "react-useanimations";
import alertOctagon from "react-useanimations/lib/alertOctagon";
import checkbox from "react-useanimations/lib/checkBox";
import loading2 from "react-useanimations/lib/loading2";

import { clsxm } from "@peykio/clsxm";

import { Button, ButtonProps } from "@dewrangle/ui";

import { useTimer } from "@/hooks/useTimer";

export type ActionButtonStateHandler = Dispatch<
  SetStateAction<ActionButtonProps["state"]>
>;

export interface ICopyTextButton extends ButtonProps {
  getText: () => string;
  iconSize?: number;
  align?: "left" | "right" | "center";
}

export const CopyTextButton = ({
  getText,
  iconSize,
  children,
  align = "center",
  ...rest
}: ICopyTextButton) => {
  const [copying, setCopying] = useTimer(5, false);
  const [text, setText] = useState("");
  useEffect(() => {
    setText(getText());
  }, [getText]);

  return (
    <Button
      onClick={async () => {
        await navigator.clipboard?.writeText(text);
        setCopying(true);
      }}
      {...rest}
    >
      {align === "right" && children}
      {copying ? (
        <AccessibleIcon label="Text copied">
          <ClipboardCheck size={iconSize} />
        </AccessibleIcon>
      ) : (
        <AccessibleIcon label="Copy text">
          <ClipboardCopy size={iconSize} />
        </AccessibleIcon>
      )}
      {align === "left" && children}
    </Button>
  );
};

export type ActionButtonProps = {
  state: "idle" | "loading" | "success" | "error";
} & ButtonProps;

export const stack =
  "relative grid [grid-area:1/1] place-items-center place-content-center h-full duration-300 transition ease-in-out opacity-100 translate-x-0";
export const stackHidden = "invisible";
export const stackEnter = "translate-x-1/2";
export const stackLeave = "-translate-x-1/2";

export const ActionButton = ({
  state = "idle",
  children,
  size,
  variant,
  ...rest
}: ActionButtonProps) => {
  const stateAsVariant: Record<
    ActionButtonProps["state"],
    ButtonProps["variant"]
  > = {
    idle: variant,
    loading: variant,
    success: "success",
    error: "destructive",
  };

  const stateAsChild: Record<ActionButtonProps["state"], ReactNode> = {
    idle: (
      <span
        className={clsxm({
          [stack]: true,
          [stackHidden]: state !== "idle",
          [stackEnter]: ["error", "success"].includes(state),
          [stackLeave]: ["loading"].includes(state),
        })}
      >
        {children}
      </span>
    ),
    loading: (
      <span
        className={clsxm({
          [stack]: true,
          [stackHidden]: state !== "loading",
          [stackEnter]: ["idle"].includes(state),
          [stackLeave]: ["error", "success"].includes(state),
        })}
      >
        <UseAnimations animation={loading2} size={16} fillColor="white" />
      </span>
    ),
    success: (
      <span
        className={clsxm({
          [stack]: true,
          [stackHidden]: state !== "success",
          [stackEnter]: ["loading"].includes(state),
          [stackLeave]: ["idle"].includes(state),
        })}
      >
        <UseAnimations
          animation={checkbox}
          size={24}
          autoplay={true}
          strokeColor="white"
        />
      </span>
    ),
    error: (
      <span
        className={clsxm({
          [stack]: true,
          [stackHidden]: state !== "error",
          [stackEnter]: ["loading"].includes(state),
          [stackLeave]: ["idle"].includes(state),
        })}
      >
        <UseAnimations
          animation={alertOctagon}
          size={24}
          strokeColor="white"
          loop={false}
        />
      </span>
    ),
  };

  const disabled = rest.disabled ?? state !== "idle";

  return (
    <Button
      size={size}
      variant={stateAsVariant[state]}
      className={clsxm({
        "grid [grid-template:1fr/1fr]": true,
        ["disabled:hover:!cursor-not-allowed"]: disabled,
      })}
      disabled={state !== "idle"}
      {...rest}
    >
      {stateAsChild.idle}
      {stateAsChild.loading}
      {stateAsChild.success}
      {stateAsChild.error}
    </Button>
  );
};
