import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import {
  ControllerRenderProps,
  UseFormReturn,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";
import * as z from "zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  Input,
  InputProps,
} from "@dewrangle/ui";

import { Layout } from "@/components/uikit";
import { formatFileSize } from "@/lib/string";

export function resetFileInputOnClick({
  form,
  field,
}: {
  form: UseFormReturn<any, any, any>;
  field: ControllerRenderProps<any, any>;
}) {
  return () => {
    // reset the event target so that re-selecting the same
    // file will trigger a change event.
    form.setValue(field.name, null, { shouldValidate: true });
    const [inputElement] = document.getElementsByName(field.name);
    if (inputElement) {
      (inputElement as HTMLInputElement).value = "";
    }
  };
}

export const FileUploadForm = ({
  id,
  fileInputProps = {},
  onSubmit,
  onChange,
}: {
  id: string;
  fileInputProps?: InputProps;
  onChange?: (args: { isValid: boolean }) => void;
  onSubmit: (formData: { files?: FileList | null }) => Promise<void>;
}) => {
  const formSchema = z.object({
    files: z
      .instanceof(FileList)
      .optional()
      .nullable()
      .refine((fileList) => !!fileList?.length),
  });
  type FormData = z.infer<typeof formSchema>;
  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
  });
  const { isValid } = useFormState({ control: form.control });
  const { files } = useWatch({ control: form.control });
  useEffect(() => onChange?.({ isValid }), [onChange, isValid]);

  return (
    <Form {...form}>
      <form id={id} onSubmit={form.handleSubmit(() => onSubmit({ files }))}>
        <Layout.Row className="mt-auto justify-between">
          <FormField
            control={form.control}
            name="files"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...fileInputProps}
                    required
                    type="file"
                    name={field.name}
                    multiple={false}
                    onChange={(e) => {
                      form.setValue(field.name, e.target.files, {
                        shouldValidate: true,
                      });
                    }}
                    onClick={resetFileInputOnClick({ form, field })}
                    className="border-none p-0 cursor:pointer file:cursor-pointer file:text-secondary-foreground file:mr-4 file:py-2 file:px-6 file:border-0 file:rounded-md file:bg-secondary file:hover:bg-accent file:transition"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          {files?.[0] && (
            <FormDescription>
              Size: {formatFileSize(files[0].size)}
            </FormDescription>
          )}
        </Layout.Row>
      </form>
    </Form>
  );
};
